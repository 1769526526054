<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <h2 class="mb-1 text-primary">
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.title') }}
        </h2>

        <b-form @submit.prevent="fetchElements">
          <validation-observer ref="elementForm" #default="{ invalid }">
            <b-row>
              <!-- ARL Field -->
              <b-col cols="12" lg="4" md="4">
                <b-form-group label-for="arl">
                  <template v-slot:label>
                    {{ $t('billing.search.field.arl') }}
                    <span class="text-danger">*</span>
                  </template>
                  <v-select
                    v-model="elementData.arl_id"
                    :options="arlList"
                    label="name"
                    value="id"
                    :reduce="(option) => option.id"
                    :placeholder="$t('list.defaultOption')"
                    required
                  />
                </b-form-group>
              </b-col>

              <!-- Invoice Number Field -->
              <b-col cols="12" lg="4" md="4">
                <b-form-group label-for="number">
                  <template v-slot:label>
                    {{ $t('fullInvoice.search.field.number') }}
                  </template>
                  <b-form-input v-model="elementData.number" />
                </b-form-group>
              </b-col>

              <!-- Authorization Code Field -->
              <b-col cols="12" lg="4" md="4">
                <b-form-group label-for="authorizationCode">
                  <template v-slot:label>
                    {{ $t('fullInvoice.search.field.authorizationCode') }}
                  </template>
                  <b-form-input v-model="elementData.authorizationCode" />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- Worker Document Number Field -->
              <b-col cols="12" lg="4" md="4">
                <b-form-group label-for="workerDocumentNumber">
                  <template v-slot:label>
                    {{ $t('fullInvoice.search.field.workerDocumentNumber') }}
                  </template>
                  <b-form-input v-model="elementData.workerDocumentNumber" type="text" />
                </b-form-group>
              </b-col>

              <!-- Worker Name Field -->
              <b-col cols="12" lg="4" md="4">
                <b-form-group label-for="workerName">
                  <template v-slot:label>
                    {{ $t('fullInvoice.search.field.workerName') }}
                  </template>
                  <b-form-input v-model="elementData.workerName" />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- From Date Field -->
              <b-col cols="12" lg="4" md="4">
                <date-picker
                  :element-required="true"
                  element-field="from"
                  :root-element-data="elementData"
                  :element-label="$t('billing.search.field.from')"
                />
              </b-col>

              <!-- Until Date Field -->
              <b-col cols="12" lg="4" md="4">
                <date-picker
                  :element-required="true"
                  element-field="until"
                  :root-element-data="elementData"
                  :element-label="$t('billing.search.field.to')"
                />
              </b-col>

              <!-- Status Field -->
              <b-col cols="12" lg="4" md="4">
                <b-form-group label-for="status">
                  <template v-slot:label>
                    {{ $t('fullInvoice.search.field.status') }}
                    <span class="text-danger">*</span>
                  </template>
                  <v-select
                    v-model="elementData.status_id"
                    :options="invoiceStatusList"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id"
                    :placeholder="$t('list.defaultOption')"
                    required
                  />
                </b-form-group>
              </b-col>

              <!-- Search Button -->
              <b-col cols="12" lg="4" md="4" class="d-flex align-items-end">
                <b-button
                  variant="primary"
                  type="submit"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1 ml-1"
                  :disabled="invalid"
                >
                  {{ $t('actions.search') }}
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
        <hr />

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('table.search.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('table.search.entries') }}</label>
          </b-col>
          <b-col cols="12" md="6" class="d-flex justify-content-start mb-1 mb-md-0">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')"
            />
            <a
              id="view-report-button"
              class="btn btn-primary"
              :href="getUrl()"
              target="_blank"
            >
              <span class="text-nowrap">{{ $t('invoice.actions.download') }}</span>
            </a>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refElementsListTable"
        class="position-relative"
        :items="itemsFormatted"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')"
      >
        <template #cell(billing_status)="data">
          <b-badge :id="`edit-button-${data.item.id}`" pill class="text-capitalize">
            {{ getStatus(data.item.billing_status) }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-col>
            <b-button
              :id="`edit-button-${data.item.id}`"
              style="margin: 1px"
              variant="primary"
              :to="{
                name: 'apps-billing-invoice-consult-edit',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-tooltip
              :target="`edit-button-${data.item.id}`"
              :title="$t('actions.edit')"
              triggers="hover"
              :noninteractive="true"
              fallback-placement="counterclockwise"
            >
            </b-tooltip>
          </b-col>
        </template>
      </b-table>

      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BTooltip,
  BFormGroup,
  BFormCheckbox,
  BForm,
} from 'bootstrap-vue'
import store from '@/store'
import moment from 'moment'
import { mapState } from 'vuex'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import qs from 'qs'
import vSelect from 'vue-select'
import ElementStoreModule from '../storeModule'
import '@core/scss/vue/libs/vue-select.scss'

const SIZE_LIST = [50, 100]

export default {
  components: {
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormCheckbox,
    BForm,
    ValidationObserver,
    DatePicker,
    vSelect,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      itemsFormatted: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      currentPath: window.location.pathname,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      selected_element: {},
      currentSelection: {},
      tableColumns: [],
      elementData: {
        arl_id: null,
        number: undefined,
        authorizationCode: undefined,
        workerDocumentNumber: undefined,
        workerName: undefined,
        from: undefined,
        until: undefined,
        status_id: null,
      },
      timer: null,
      allElements: [],
      downloadLink: undefined,
      arlList: [],
      invoiceStatusList: [],
    }
  },
  created() {
    this.RESOURCES_ELEMENT_NAME = 'fullInvoice'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-${this.RESOURCES_ELEMENT_NAME}`
  },
  computed: {
    ...mapState('caseSearch', ['searchData']),
  },
  watch: {
    perPage(val) {
      this.fetchElements()
    },
    currentPage(val) {
      this.fetchElements()
    },
    query(val) {
      this.currentPage = 1
      this.fetchElements()
    },/*
    'elementData.arl_id': function (val) {
      this.fetchElements()
    },
    'elementData.status_id': function (val) {
      this.fetchElements()
    },
    'elementData.number': function (val) {
      this.fetchElements()
    },
    'elementData.from': function (val) {
      this.fetchElements()
    },
    'elementData.until': function (val) {
      this.fetchElements()
    },*/
  },
  async mounted() {
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )

    this.date = router.currentRoute.params.date
    this.arlList = await this.fetchARLs()
    this.invoiceStatusList = await this.fetchInvoiceStatus()

    const today = new Date()
    const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate())
    
    this.elementData.from = this.formatDate(threeMonthsAgo)
    this.elementData.until = this.formatDate(today)

    this.tableColumns = [
      {
        key: 'number',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.number`),
        sortable: true,
      },
      {
        key: 'arl.name',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.arl`),
        sortable: true,
      },
      {
        key: 'date',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.date`),
        sortable: true,
      },
      {
        key: 'value',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.value`),
        sortable: true,
      },
      {
        key: 'fuente',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.fuente`),
        sortable: true,
      },
      {
        key: 'ica',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.ica`),
        sortable: true,
      },
      {
        key: 'other_taxes',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.other_taxes`),
        sortable: true,
      },
      {
        key: 'iva',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.iva`),
        sortable: true,
      },
      {
        key: 'received_value',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.received_value`),
        sortable: true,
      },
      {
        key: 'billing_status',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.status`),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.actions`),
        thStyle: 'width: 300px;',
      },
    ]
    var data = JSON.parse(sessionStorage.getItem(this.currentPath))

    if (data != null) {
      this.query = data.query
      this.elementData.number = data.number
      this.elementData.from = data.from
      this.elementData.until = data.until
      this.elementData.status_id = data.status_id
      this.elementData.arl_id = data.arl_id
    } else {
      this.elementData = {
        number: undefined,
        arl_id: 0,
        status_id: 0,
        from: undefined,
        until: undefined,
      }
    }

    this.fetchElements()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
    clearInterval(this.timer)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    formatDate(date) {
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
    },
    getStatus(status) {
      return status ? status.value : '...'
    },
    async fetchARLs() {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchCompanies`, {})
        .then((response) => {
          const list = response.data.items
          list.unshift({ id: 0, name: 'TODAS' })
          return list
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return undefined
          }
        })
    },
    async fetchInvoiceStatus() {
      return store
        .dispatch(
          `${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchInvoiceStatus`,
          {}
        )
        .then((response) => {
          const list = response.data.items
          list.unshift({ id: 0, value: 'TODOS' })
          return list
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return undefined
          }
        })
    },
    getUrl() {
      const uri_params = qs.stringify(this.elementData, { indices: false })
      return `${process.env.VUE_APP_BASE_API}/api/v1/billing/debt_portfolio?${uri_params}`
    },
    filterJson(jsonData) {
      const filterList = this.tableColumns.map((a) => a.key)
      filterList.pop()
      return jsonData.map((v) => filterList.map((j) => v[j]))
    },
    getHeader() {
      const res = {}
      for (let i = 0; i < this.tableColumns.length; i++) {
        res[this.tableColumns[i].key] = this.tableColumns[i].label
      }
      return res
    },
    async send(elementData) {
      const method = `${this.ELEMENT_APP_STORE_MODULE_NAME}/sendElement`
      const _self = this

      elementData.in_helisa = 0

      return store
        .dispatch(method, elementData)
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.send.success`
              ),
              icon: 'SendIcon',
              variant: 'success',
              text: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.send.success`
              ),
            },
          })
          return true
        })
        .catch((error) => {
          console.info(error.message)
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                `${_self.RESOURCES_ELEMENT_NAME}.action_results.send.failure`
              ),
              icon: 'SendIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
    },
    fetchElements() {
      sessionStorage.setItem(
        this.currentPath,
        JSON.stringify({
          ...this.elementData,
          query: this.query,
        })
      )

      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchElements`, {
          queryParams: {
            ...this.elementData,
            perPage: this.perPage,
            page: this.currentPage,
            query: this.query,
          },
        })
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total

          console.info(response.data)

          this.itemsFormatted = this.items.map((item) => ({
            ...item,
            date: moment(item.date).format('DD/MM/YYYY'),
            due_date: moment(item.due_date).format('DD/MM/YYYY'),
          }))
        })
        .catch(() => {
          console.error('Error fetching elements')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
